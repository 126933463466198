import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Forside from '../Forside/Forside';
import AboutSide from '../AboutSide/AboutSide';
import OmOssSide from '../OmOssSide/OmOssSide';
import KontaktSide from '../KontaktSide/KontaktSide';
import AktueltSide from '../AktueltSide/AktueltSide';
import OrakelSide from '../OrakelSide/OrakelSide';
import Scroller from './Scroller';
import styles from './styles.module.scss';

function Layout() {
  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.content} id={'content'}>
        <Scroller />
        <Routes>
          <Route path={'/'} element={<Forside />} />
          <Route path={'omoss'} element={<OmOssSide />} />
          <Route path={'ressurser'} element={<AboutSide />} />
          <Route path={'aktuelt'} element={<AktueltSide />} />
          <Route path={'kontakt'} element={<KontaktSide />} />
          <Route path={'orakel'} element={<OrakelSide />} />
          <Route path={'*'} element={<Forside />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
