import React from 'react';
import felleslosning from '../../assets/felleslosning.png';
import felleskomponenter from '../../assets/felleskomponenter.png';
import bruksomrade from '../../assets/bruksomrade.png';
import rif from '../../assets/rif.png';
import juridisk from '../../assets/juridisk.png';
import klima from '../../assets/klima.png';
import reisemonster from '../../assets/reisemonster.png';
import person from '../../assets/person.png';
import konkurranse from '../../assets/konkurranse.png';
import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';

function Samarbeidsstruktur() {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' });

  const underboks = (text: string, icon: string) => {
    return (
      <div className={styles.underboks}>
        {text}
        {!isMobileScreen && <img src={icon} alt="" />}
      </div>
    );
  };

  const underunderboks = (text: string, icon: string) => {
    return (
      <div className={styles.underunderboks}>
        <div className={styles.imageContainer}>
          <img src={icon} alt="" />
        </div>
        {text}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.toppboks}>Ledelse</div>
      <div className={styles.topplinje}>
        <span />
      </div>
      <div className={styles.linje}>
        <span />
      </div>
      <div className={styles.bokser}>
        <div className={styles.boks}>
          {underboks('Tekniske løsninger for datadeling', felleslosning)}
          {!isMobileScreen &&
            underunderboks('Felleskomponenter', felleskomponenter)}
        </div>
        <div className={styles.boks}>
          {underboks('Verdicase', bruksomrade)}
          {!isMobileScreen &&
            underunderboks('Data om reisemønster', reisemonster)}
          {!isMobileScreen &&
            underunderboks('Miljøavtrykk på persontransport', klima)}
          {!isMobileScreen && underunderboks('Reiseinformasjonsflyt', rif)}
        </div>
        <div className={styles.boks}>
          {underboks('Juridisk', juridisk)}
          {!isMobileScreen && underunderboks('Personopplysninger', person)}
          {!isMobileScreen &&
            underunderboks('Forretningshemmeligheter', konkurranse)}
        </div>
      </div>
    </div>
  );
}

export default Samarbeidsstruktur;
