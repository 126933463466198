import {useQuery} from "@tanstack/react-query";
import axios from "axios";

export const QK_ORAKEL = "QK_ORAKEL";
//const baseUrl = "http://localhost:8080";
const baseUrl = "https://fdk-llm.dev.entur.io";

export const useOrakel = (query: string, version: string) => {
  return useQuery({
    queryKey: [QK_ORAKEL, query, version],
    queryFn: async () => {
      const path = `${baseUrl}/llm/${version}`;
      const res = await axios.post<LLM_Response>(path, {query: query});
      return res.data;
    },
    enabled: false
  });
};

type LLM_Response = {
  llm: string;
  links: string[]
  titles: string[]
}
