import React from 'react';
import CustomSection from '../CustomSection/CustomSection';
import { Heading1, Link, Paragraph } from '@entur/typography';
import { ReactComponent as KontaktSvg } from '../../assets/decoration/kontakt.svg';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

function KontaktSide() {
  return (
    <>
      <Helmet>
        <title>Kontakt oss | Samferdselsdata</title>
      </Helmet>
      <CustomSection>
        <div className={styles.kontaktSection}>
          <div>
            <Heading1>Kontakt oss</Heading1>
            <Paragraph>
              Lurer du på noe eller har en tilbakemelding? Du kan enten kontakte
              oss på Slack eller sende oss en hyggelig epost.
            </Paragraph>
            <Paragraph>
              <Link href={'mailto:kontakt@samferdselsdata.no'} target={'_blank'}>
                kontakt@samferdselsdata.no
              </Link>
            </Paragraph>
          </div>
          <KontaktSvg className={styles.bilde} />
        </div>
      </CustomSection>
    </>
  );
}

export default KontaktSide;
