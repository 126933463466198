import React, {ChangeEvent, KeyboardEvent, useState} from 'react';
import { Heading1, LeadParagraph, Link, Paragraph } from '@entur/typography';
import { TextField } from '@entur/form';
import styles from './styles.module.scss';
import { Contrast } from '@entur/layout';
import { useOrakel } from '../../hooks/orakel';
import { Loader } from '@entur/loader';
import Markdown from 'react-markdown';
import { ReactComponent as Crystal1 } from '../../assets/decoration/crystal_1.svg';
import { ReactComponent as Crystal2 } from '../../assets/decoration/crystal_2.svg';
import { Helmet } from 'react-helmet';
import CustomSection from '../CustomSection/CustomSection';

function OrakelSide() {
  const [query, setQuery] = useState<string>('');
  const { data: response, isLoading, refetch } = useOrakel(query, "v3");

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onEnterPressed = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onQuery();
    }
  };

  const onQuery = () => {
    refetch();
  };

  const LinkInjector = (props: any) => {
    const title = props.children.toUpperCase();

    const index = response!!.titles.findIndex(el => el.startsWith(title.substring(0, el.length)));
    const href = response!!.links[index];

    if (index === -1 || !href) {
      return <strong>{props.children}</strong>;
    }

    response!!.titles.splice(index, 1);
    response!!.links.splice(index, 1);

    return (
      <Link href={href} target="_blank" rel="noreferrer">
        {props.children}
      </Link>
    );
  };

  const LinkRenderer = (props: any) => {
    return (
      <Link href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </Link>
    );
  };

  return (
    <>
      <Helmet>
        <title>Orakel | Samferdselsdata</title>
      </Helmet>
      <Contrast className={styles.container}>
        <div className={styles.decoratedTopBar}>
          <Crystal2 />
          <div className={styles.midSection}>
            <div>
              <div className={styles.tag}>Pilot</div>
              <Heading1 className={styles.header}>Orakelkatalogen</Heading1>
            </div>
            <LeadParagraph className={styles.ingress}>
              Enklere søk for data.norge.no
            </LeadParagraph>
          </div>
          <Crystal1 />
        </div>
        <div className={styles.query}>
          <TextField
            onChange={onTextChanged}
            onKeyDown={onEnterPressed}
            label={'Melding til Orakelet'}
          />
        </div>
        <div className={styles.response}>
          {isLoading && <Loader />}
          {response && !isLoading && (
            <>
              <span className={styles.responseBar}></span>
              <div className={styles.responseText}>
                <Markdown
                  components={{ a: LinkRenderer, strong: LinkInjector }}
                >
                  {response.llm.replace(/\n\n\s+/g, '\n\n').trim()}
                </Markdown>
              </div>
            </>
          )}
        </div>
      </Contrast>
      <CustomSection className={styles.slideshare}>
        <Heading1>Orakelkatalogen - Basert på LLM</Heading1>
        <LeadParagraph>Slik bygget vi en AI-basert søketjeneste</LeadParagraph>
        <Paragraph>
          <strong>Teknisk dokumentasjon: </strong>
          <Link
            href={'https://github.com/entur/sd-orakelkatalog'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            https://github.com/entur/sd-orakelkatalog
          </Link>
        </Paragraph>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSq8d2g_zAv-k3CMX2kWPgxHWCbr0Qv8G_EAS57P_w5W9ti3K4-DUktmgdJbPPPPw/embed?start=false&loop=false&delayms=3000"
          frameBorder="0"
          title="FDK LLM Powerpoint"
          width="1280"
          height="749"
        ></iframe>
      </CustomSection>
    </>
  );
}

export default OrakelSide;
